import { doMergeWithStandardConfig, doNotMergeWithStandardConfig } from '@proalpha/configuration';
import { PaIcon, PaInput, PaOverlay } from '@proalpha/core-patterns';
import { configAssetLocation } from '@proalpha/core-patterns/util';
import { createStorage } from '@proalpha/storage';
import { MicroFrontendOverrideDialog } from '../web-component/microFrontendOverridesDialog';
import { MicroFrontendOverridesNotification } from '../web-component/microFrontendOverridesNotification';
import { getCustomHosts, isMicroFrontendName, mergeDefaultAndCustomHosts } from './helperFunctions';
import { microFrontends } from './shell';
const localStorage = createStorage('shell').localStorage;
// eslint-disable-next-line
const corePatternsStyle = require('@proalpha/core-patterns/core-patterns.css').default.toString();
export const initMicroFrontendOverrides = () => {
    loadCustomHosts();
    configAssetLocation(location.origin);
    defineCustomElements();
    initUserInterface();
};
const loadCustomHosts = () => {
    const customMicroFrontendHosts = localStorage.getItem('customHosts');
    if (customMicroFrontendHosts) {
        mergeDefaultAndCustomHosts(microFrontends, customMicroFrontendHosts);
        configureConfigurationPackage(customMicroFrontendHosts);
    }
};
const configureConfigurationPackage = (customMicroFrontendHosts) => {
    Object.keys(microFrontends).forEach(microFrontendName => {
        if (isMicroFrontendName(microFrontendName)) {
            if (customMicroFrontendHosts[microFrontendName]) {
                doNotMergeWithStandardConfig(microFrontendName);
            }
            else {
                doMergeWithStandardConfig(microFrontendName);
            }
        }
    });
};
const defineCustomElements = () => {
    customElements.define('pa-input-shell', PaInput);
    customElements.define('pa-icon-shell', PaIcon);
    customElements.define('pa-overlay-shell', PaOverlay);
    customElements.define('micro-frontend-overrides-dialog', MicroFrontendOverrideDialog);
    customElements.define('micro-frontend-overrides-notification', MicroFrontendOverridesNotification);
};
export const saveCustomHosts = () => {
    const customMicroFrontendHosts = getCustomHosts(microFrontends);
    localStorage.setItem('customHosts', customMicroFrontendHosts);
    configureConfigurationPackage(customMicroFrontendHosts);
};
const initUserInterface = () => {
    let microFrontendOverridesDialog = null;
    const corePatternsStyleElement = document.createElement('style');
    corePatternsStyleElement.innerHTML = corePatternsStyle;
    let dialogIsDisplayed = false;
    const addDialogToDOM = () => {
        microFrontendOverridesDialog = document.createElement('micro-frontend-overrides-dialog');
        document.head.appendChild(corePatternsStyleElement);
        document.body.appendChild(microFrontendOverridesDialog);
        document.addEventListener('keydown', escapeKeyListener);
        dialogIsDisplayed = true;
    };
    const removeDialogFromDOM = () => {
        if (microFrontendOverridesDialog) {
            document.body.removeChild(microFrontendOverridesDialog);
            document.head.removeChild(corePatternsStyleElement);
            document.removeEventListener('keydown', escapeKeyListener);
            dialogIsDisplayed = false;
        }
    };
    const escapeKeyListener = (keyEvent) => {
        if (keyEvent.key === 'Escape') {
            removeDialogFromDOM();
        }
    };
    document.addEventListener('keydown', keyEvent => {
        if (keyEvent.altKey && keyEvent.key === 'o') {
            if (dialogIsDisplayed) {
                removeDialogFromDOM();
            }
            else {
                addDialogToDOM();
            }
        }
    });
};
