import { __awaiter } from "tslib";
import { getInitializer, normalizeUrl, showErrorView } from '../shell/helperFunctions';
export class MicroFrontend {
    constructor(name, defaultHost, scripts) {
        this.defaultHost = defaultHost;
        this.scripts = scripts;
        this.customHost = false;
        this.alreadyBootstrapped = false;
        this.alreadyLoaded = false;
        this.problemLoading = false;
        this.loadedHost = null;
        this._active = false;
        this.initializer = null;
        this.name = name;
        this.ownHost = this.defaultHost;
    }
    set ownHost(ownHost) {
        const newOwnHost = ownHost;
        if (this.ownHost) {
            this.customHost = newOwnHost !== this.defaultHost;
        }
        this._ownHost = newOwnHost;
        this.dispatchMicroFrontendChangedEvent();
    }
    get ownHost() {
        return this._ownHost;
    }
    set active(active) {
        this._active = active;
        this.dispatchMicroFrontendChangedEvent();
    }
    get active() {
        return this._active;
    }
    loadMicroFrontend() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const promises = [];
                // eslint-disable-next-line no-console
                console.log(`load "${this.name}" started (${this.ownHost})`);
                this.scripts.forEach(element => {
                    promises.push(new Promise((resolve, reject) => {
                        const script = document.createElement('script');
                        script.addEventListener('load', () => resolve());
                        script.addEventListener('abort', () => reject(`Loading of the script "${element}" aborted`));
                        script.addEventListener('error', () => reject(`The script "${element}" could not be loaded`));
                        script.setAttribute('src', normalizeUrl(this.ownHost) + '/' + element);
                        script.setAttribute('type', 'text/javascript');
                        document.body.appendChild(script);
                    }));
                });
                yield Promise.all(promises);
                this.loadMicroFrontendSuccessful();
                this.dispatchMicroFrontendChangedEvent();
                // eslint-disable-next-line no-console
                console.log(`load "${this.name}" finished`);
            }
            catch (error) {
                this.loadMicroFrontendFailure();
                this.dispatchMicroFrontendChangedEvent();
                throw error instanceof Error ? error : new Error(error);
            }
        });
    }
    loadMicroFrontendSuccessful() {
        this.alreadyLoaded = true;
        this.problemLoading = false;
        this.loadedHost = this.ownHost;
        this.initializer = getInitializer(this.name);
    }
    loadMicroFrontendFailure() {
        this.problemLoading = true;
        this.loadedHost = this.ownHost;
    }
    bootstrap() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.initializer) {
                // eslint-disable-next-line no-console
                console.log(`bootstrap "${this.name}" started`);
                yield this.initializer.bootstrap(this.ownHost);
                // eslint-disable-next-line no-console
                console.log(`bootstrap "${this.name}" finished`);
                this.alreadyBootstrapped = true;
                this.dispatchMicroFrontendChangedEvent();
            }
            else {
                const errorText = `Initializer for micro frontend "${this.name}" not found in global scope.`;
                // eslint-disable-next-line no-console
                console.error(errorText);
                showErrorView('Micro frontend initializer not found!', errorText);
            }
        });
    }
    mount() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.initializer) {
                const microFrontendTagElement = document.createElement(this.initializer.tagName);
                document.body.prepend(microFrontendTagElement);
                // eslint-disable-next-line no-console
                console.log(`mount "${this.name}" started`);
                yield this.initializer.mount(microFrontendTagElement);
                // eslint-disable-next-line no-console
                console.log(`mount "${this.name}" finished`);
            }
        });
    }
    unmount() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.initializer) {
                // eslint-disable-next-line no-console
                console.log(`unmount "${this.name}" started`);
                yield this.initializer.unmount();
                // eslint-disable-next-line no-console
                console.log(`unmount "${this.name}" finished`);
                const activeHtmlElement = document.querySelector(this.initializer.tagName);
                if (activeHtmlElement) {
                    document.body.removeChild(activeHtmlElement);
                }
            }
        });
    }
    isAlreadyLoaded() {
        return this.alreadyLoaded;
    }
    isAlreadyBootstrapped() {
        return this.alreadyBootstrapped;
    }
    hasProblemLoading() {
        return this.problemLoading;
    }
    resetHost() {
        this.ownHost = this.defaultHost;
    }
    hasCustomHost() {
        return this.customHost;
    }
    isEqual(otherMicroFrontend) {
        return !!(otherMicroFrontend && otherMicroFrontend.name === this.name);
    }
    dispatchMicroFrontendChangedEvent() {
        window.dispatchEvent(new CustomEvent('shell-micro-frontend-changed'));
    }
}
