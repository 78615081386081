import { html, LitElement, unsafeCSS } from 'lit-element';
import { isMicroFrontendName } from '../shell/helperFunctions';
import { saveCustomHosts } from '../shell/microFrontendOverrides';
import { microFrontends } from '../shell/shell';
import { checkIfMicroFrontendNeedsReloadNotification } from './helper/microFrontendOverridesDialogHelper';
import { MicroFrontendOverridesNotification } from './microFrontendOverridesNotification';
// eslint-disable-next-line
const microFrontendOverrideDialogStyle = require('./microFrontendOverridesDialog.css').default.toString();
export class MicroFrontendOverrideDialog extends LitElement {
    constructor() {
        super();
        this.visible = false;
        this.enteredHostNames = {};
        this.showReloadNotification = false;
        this.microFrontendChangeListener = () => this.repaint();
        window.addEventListener('shell-micro-frontend-changed', this.microFrontendChangeListener);
        Object.entries(microFrontends).map(([microFrontendName, microFrontend]) => {
            if (microFrontend && isMicroFrontendName(microFrontendName)) {
                this.enteredHostNames[microFrontendName] = microFrontend.ownHost;
            }
        });
        this.checkIfReloadNotificationIsNeeded();
    }
    static get styles() {
        return [unsafeCSS(microFrontendOverrideDialogStyle)];
    }
    connectedCallback() {
        super.connectedCallback();
        setTimeout(() => {
            this.visible = true;
            this.repaint();
        });
    }
    disconnectedCallback() {
        window.removeEventListener('shell-micro-frontend-changed', this.microFrontendChangeListener);
    }
    repaint() {
        this.checkIfReloadNotificationIsNeeded();
        this.requestUpdate().catch(error => {
            throw error instanceof Error ? error : new Error(error);
        });
    }
    checkIfReloadNotificationIsNeeded() {
        const reloadNotificationNeeded = Object.values(microFrontends).some(microFrontend => {
            if (microFrontend) {
                return checkIfMicroFrontendNeedsReloadNotification({
                    ownHost: microFrontend.ownHost,
                    loadedHost: microFrontend.loadedHost,
                    active: microFrontend.active,
                    problemLoading: microFrontend.hasProblemLoading(),
                });
            }
            return false;
        });
        if (reloadNotificationNeeded) {
            this.showReloadNotification = true;
        }
        else {
            this.hideReloadNotification();
        }
    }
    hideReloadNotification() {
        var _a;
        const notificationElement = (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('micro-frontend-overrides-notification');
        if (notificationElement && notificationElement instanceof MicroFrontendOverridesNotification) {
            notificationElement.hide().then(() => {
                this.showReloadNotification = false;
                this.repaint();
            }, error => {
                throw error instanceof Error ? error : new Error(error);
            });
        }
        else {
            this.showReloadNotification = false;
        }
    }
    saveHost(microFrontend) {
        var _a;
        microFrontend.ownHost = (_a = this.enteredHostNames[microFrontend.name]) !== null && _a !== void 0 ? _a : '';
        saveCustomHosts();
        this.repaint();
    }
    resetHost(microFrontend) {
        microFrontend.resetHost();
        this.enteredHostNames[microFrontend.name] = microFrontend.ownHost;
        saveCustomHosts();
        this.repaint();
    }
    jumpToHost(microFrontend) {
        history.pushState({}, '', '/' + microFrontend.name);
    }
    modifyHost(microFrontendName, event) {
        var _a;
        const inputEvent = event.detail;
        this.enteredHostNames[microFrontendName] = (_a = inputEvent.value) !== null && _a !== void 0 ? _a : '';
        this.repaint();
    }
    render() {
        return html `
      <pa-overlay-shell></pa-overlay-shell>
      <div class="microFrontendDialogContainer ${this.visible ? 'visible' : ''}">
        ${this.showReloadNotification
            ? html `
              <micro-frontend-overrides-notification
                notificationMessage="Damit die Einstellung wirksam werden muss die Seite neu geladen werden (F5)"
              ></micro-frontend-overrides-notification>
            `
            : html ``}
        ${Object.entries(microFrontends).map(([microFrontendName, microFrontend]) => {
            if (microFrontend && isMicroFrontendName(microFrontendName)) {
                const saveButtonDisabled = microFrontend.ownHost === this.enteredHostNames[microFrontendName];
                const resetButtonDisabled = !microFrontend.hasCustomHost();
                const jumpButtonDisabled = microFrontend.active;
                return html `
              <div class="microFrontendContainer">
                <pa-input-shell
                  input-id="overrides-input-${microFrontendName}"
                  label="${microFrontendName}${microFrontend.hasProblemLoading()
                    ? ' - konnte nicht geladen werden!'
                    : ''}"
                  value="${microFrontend.ownHost}"
                  @paInput="${this.modifyHost.bind(this, microFrontendName)}"
                ></pa-input-shell>
                <div class="actionBar">
                  <div class="buttonContainer">
                    <pa-icon-shell
                      class=${saveButtonDisabled ? 'disabled' : ''}
                      icon="check"
                      icon-style="solid"
                      title="Host speichern"
                      @click=${() => !saveButtonDisabled && this.saveHost(microFrontend)}
                    ></pa-icon-shell>
                  </div>
                  <div class="buttonContainer">
                    <pa-icon-shell
                      class=${resetButtonDisabled ? 'disabled' : ''}
                      icon="times"
                      icon-style="solid"
                      title="Host zurücksetzen"
                      @click=${() => !resetButtonDisabled && this.resetHost(microFrontend)}
                    ></pa-icon-shell>
                  </div>
                  <div class="buttonContainer">
                    <pa-icon-shell
                      class=${jumpButtonDisabled ? 'disabled' : ''}
                      icon="share"
                      icon-style="solid"
                      title="Host aufrufen"
                      @click=${() => !jumpButtonDisabled && this.jumpToHost(microFrontend)}
                    ></pa-icon-shell>
                  </div>
                </div>
              </div>
            `;
            }
            return html ``;
        })}
      </div>
    `;
    }
}
