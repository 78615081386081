import { checkMicroFrontendConfigCollection } from './model/types';
export const microFrontendCollection = checkMicroFrontendConfigCollection({
    portal: {
        // host: 'https://localhost:3001',
        scripts: ['portal.js'],
    },
    nemo: {
        // host: 'https://localhost:3004',
        scripts: ['polyfills.js', 'runtime.js', 'vendor.js', 'main.js'],
        initial: true,
    },
});
