import { microFrontendCollection } from '../microFrontends';
import { ErrorView } from '../web-component/errorView';
if (!customElements.get('error-view')) {
    customElements.define('error-view', ErrorView);
}
export const validateForMicroFrontendInitializer = (microFrontendName, value) => {
    if (isMicroFrontendInitializer(value)) {
        return value;
    }
    else {
        throw new Error(`The used API for the micro frontend "${microFrontendName}" is not correct. The property tagName and the methods bootstrap, mount and unmount must be used.`);
    }
};
export const isMicroFrontendName = (value) => value in microFrontendCollection;
export const findHTMLAnchorElement = (path) => {
    const anchorEventTarget = path.find((eventTarget) => {
        return eventTarget instanceof HTMLAnchorElement;
    });
    return anchorEventTarget !== null && anchorEventTarget !== void 0 ? anchorEventTarget : null;
};
export const showErrorView = (headerText, bodyText) => {
    removeErrorViewIfAvailable();
    const errorTagElement = document.createElement('error-view');
    errorTagElement.setAttribute('headerText', headerText);
    errorTagElement.setAttribute('bodyText', bodyText);
    document.body.prepend(errorTagElement);
};
export const removeErrorViewIfAvailable = () => {
    const errorTagElement = document.getElementsByTagName('error-view')[0];
    if (errorTagElement) {
        document.body.removeChild(errorTagElement);
    }
};
export const getInitializer = (microFrontendName) => {
    if (window.proalpha && window.proalpha.microFrontends && window.proalpha.microFrontends[microFrontendName]) {
        return validateForMicroFrontendInitializer(microFrontendName, window.proalpha.microFrontends[microFrontendName]);
    }
    else {
        return null;
    }
};
export const getUrlPathPrefix = () => {
    var _a;
    return (_a = window.location.pathname.split('/')[1]) !== null && _a !== void 0 ? _a : '';
};
export const getCustomHosts = (microFrontends) => {
    const microFrontendHosts = {};
    Object.entries(microFrontends).forEach(([microFrontendName, microFrontend]) => {
        if (microFrontend && microFrontend.hasCustomHost() && isMicroFrontendName(microFrontendName)) {
            microFrontendHosts[microFrontendName] = microFrontend.ownHost;
        }
    });
    return microFrontendHosts;
};
export const normalizeUrl = (url) => {
    let resultingUrl = url;
    if (url.endsWith('/')) {
        resultingUrl = url.substring(0, url.length - 1);
    }
    return resultingUrl;
};
export const mergeDefaultAndCustomHosts = (microFrontends, customMicroFrontendHosts) => {
    Object.entries(customMicroFrontendHosts).forEach(([key, value]) => {
        if (value && isMicroFrontendName(key)) {
            const microFrontend = microFrontends[key];
            if (microFrontend) {
                microFrontend.ownHost = value;
            }
        }
    });
};
// TODO: Use 'unknown' if issue https://github.com/Microsoft/TypeScript/issues/21732 is solved
const isMicroFrontendInitializer = (value) => value != null && 'bootstrap' in value && 'mount' in value && 'unmount' in value && 'tagName' in value;
