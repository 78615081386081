import { __decorate } from "tslib";
import { html, LitElement, property, unsafeCSS } from 'lit-element';
// eslint-disable-next-line
const errorViewStyle = require('./errorView.css').default.toString();
export class ErrorView extends LitElement {
    constructor() {
        super();
        this.headerText = 'Es wurde keine Überschrift angegeben';
        this.bodyText = 'Es wurde kein Text angegeben';
    }
    static get styles() {
        return [unsafeCSS(errorViewStyle)];
    }
    render() {
        return html `
      <h1>${this.headerText}</h1>
      <p>${this.bodyText}</p>
    `;
    }
}
__decorate([
    property()
], ErrorView.prototype, "headerText", void 0);
__decorate([
    property()
], ErrorView.prototype, "bodyText", void 0);
