import { standardConfigTestFunction } from '@proalpha/configuration';
export const checkMicroFrontendConfigCollection = (collection) => collection;
export const shellConfigTestFunction = (value) => {
    if (value == null)
        return false;
    const assumedConfig = value;
    return (standardConfigTestFunction(assumedConfig) &&
        assumedConfig.microFrontendOverride != null &&
        assumedConfig.federatedUserPoolWebClientId != null &&
        assumedConfig.matomoSiteId != null &&
        assumedConfig.additionalFeaturesEnabled != null &&
        assumedConfig.tenantsWithoutGENIUS != null);
};
