import { __decorate } from "tslib";
import { html, LitElement, property, unsafeCSS } from 'lit-element';
// eslint-disable-next-line
const notificationStyle = require('./microFrontendOverridesNotification.css').default.toString();
export class MicroFrontendOverridesNotification extends LitElement {
    constructor() {
        super();
        this.notificationMessage = 'Es wurde keine Meldung angegeben';
        this.visible = false;
    }
    static get styles() {
        return [unsafeCSS(notificationStyle)];
    }
    connectedCallback() {
        super.connectedCallback();
        setTimeout(() => {
            this.visible = true;
            this.repaint();
        });
    }
    repaint() {
        this.requestUpdate().catch(error => {
            throw error;
        });
    }
    getNotificationDivElement() {
        var _a;
        const notificationDivElement = (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('.notification');
        if (notificationDivElement) {
            return notificationDivElement;
        }
        return null;
    }
    hide() {
        return new Promise(resolve => {
            var _a;
            this.visible = false;
            const transitionEndHandler = () => {
                var _a;
                (_a = this.getNotificationDivElement()) === null || _a === void 0 ? void 0 : _a.removeEventListener('transitionend', transitionEndHandler);
                resolve();
            };
            (_a = this.getNotificationDivElement()) === null || _a === void 0 ? void 0 : _a.addEventListener('transitionend', transitionEndHandler);
            this.repaint();
        });
    }
    render() {
        return html ` <div class="notification${this.visible ? ' visible' : ''}">${this.notificationMessage}</div> `;
    }
}
__decorate([
    property()
], MicroFrontendOverridesNotification.prototype, "notificationMessage", void 0);
