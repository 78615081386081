import { __awaiter } from "tslib";
/* eslint-disable linebreak-style */
import { loadConfig } from '@proalpha/configuration';
import { Session } from '@proalpha/session';
import { microFrontendCollection } from '../microFrontends';
import { MicroFrontend } from '../model/microFrontend';
import { shellConfigTestFunction, } from '../model/types';
import { findHTMLAnchorElement, getUrlPathPrefix, isMicroFrontendName, normalizeUrl, removeErrorViewIfAvailable, showErrorView, } from './helperFunctions';
import { initMicroFrontendOverrides } from './microFrontendOverrides';
export const microFrontends = {};
const paAuthenticatorCorePatternsStyle = document.createElement('style');
let currentlySignedIn = false;
let initialMicroFrontend = null;
let displayedMicroFrontend = null;
let activeUrlPathPrefix = null;
let lazyLoadingActive = true;
const registerAllMicroFrontends = () => {
    Object.entries(microFrontendCollection).forEach(([microFrontendName, microFrontendDefinition]) => {
        if (isMicroFrontendName(microFrontendName)) {
            registerMicroFrontend(microFrontendName, microFrontendDefinition);
        }
    });
};
const registerMicroFrontend = (microFrontendName, microFrontendDefinition) => {
    const ownHost = normalizeUrl(microFrontendDefinition.host || location.origin + '/' + microFrontendName);
    const microFrontend = new MicroFrontend(microFrontendName, ownHost, microFrontendDefinition.scripts);
    if (microFrontendDefinition.initial) {
        initialMicroFrontend = microFrontend;
    }
    microFrontends[microFrontend.name] = microFrontend;
};
const initializeShell = (useLazyLoading) => {
    if (useLazyLoading !== undefined) {
        lazyLoadingActive = useLazyLoading;
    }
    const oldWinHistoryPushState = window.history.pushState.bind(window.history);
    window.history.pushState = (...args) => {
        if (currentlySignedIn) {
            oldWinHistoryPushState(...args);
            showMicroFrontend();
            callOnPushStateHandlers();
        }
    };
    window.addEventListener('popstate', () => {
        if (currentlySignedIn) {
            showMicroFrontend();
        }
    });
    document.addEventListener('click', (event) => {
        if (currentlySignedIn && event.target instanceof HTMLElement) {
            const path = event.composedPath();
            const anchorElement = findHTMLAnchorElement(path);
            if (anchorElement !== null) {
                // If a routerlink is used in Angular, the Angular Router performs the navigation itself.
                // Therefore we must not call pushState, otherwise we have several entries in the history.
                if (anchorElement.getAttribute('routerLink') === null && anchorElement.href) {
                    history.pushState({}, '', anchorElement.href);
                }
                event.preventDefault();
            }
        }
    });
    if (!lazyLoadingActive) {
        preloadAllMicroFrontends().then(() => {
            showMicroFrontend();
        }, error => {
            showMicroFrontend();
            // eslint-disable-next-line no-console
            console.error('Some micro frontends could not be loaded.', error);
        });
    }
};
const showMicroFrontend = () => {
    const urlPathPrefix = getUrlPathPrefix();
    void displayMicroFrontendFromURL(urlPathPrefix);
};
const displayMicroFrontendFromURL = (urlPathPrefix) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        if (urlPathPrefix === '') {
            switchToInitialMicroFrontend();
        }
        else if (isMicroFrontendName(urlPathPrefix)) {
            yield preloadAndSwitchToNewMicroFrontend(urlPathPrefix);
        }
        else {
            yield unmountActiveMicroFrontend();
            showErrorViewAndThrow('Page not found!', `The url path "${urlPathPrefix}" cannot be assigned to a micro frontend.`);
        }
    }
    finally {
        changeActiveUrlPathPrefix();
    }
});
const switchToInitialMicroFrontend = () => {
    if (initialMicroFrontend) {
        history.pushState({}, '', '/' + initialMicroFrontend.name);
    }
    else {
        showErrorViewAndThrow('Page not found!', 'No Micro Frontend was marked as initial.');
    }
};
const preloadAndSwitchToNewMicroFrontend = (urlPathPrefix) => __awaiter(void 0, void 0, void 0, function* () {
    const microFrontend = microFrontends[urlPathPrefix];
    if (microFrontend) {
        try {
            if (!microFrontend.isAlreadyLoaded()) {
                yield preloadMicroFrontend(microFrontend);
            }
            yield switchToNewMicroFrontend(microFrontend);
        }
        catch (error) {
            yield unmountActiveMicroFrontend();
            showErrorViewAndThrow('Micro frontend not loaded!', `The micro frontend "${microFrontend.name}" could not be loaded from "${microFrontend.ownHost}".`, error);
        }
    }
});
const showErrorViewAndThrow = (headerText, bodyText, error) => {
    showErrorView(headerText, bodyText);
    if (error) {
        throw error instanceof Error ? error : new Error(error);
    }
    else {
        throw new Error(bodyText);
    }
};
const changeActiveUrlPathPrefix = () => {
    setMicroFrontendActive(false);
    activeUrlPathPrefix = getUrlPathPrefix();
    setMicroFrontendActive(true);
};
const setMicroFrontendActive = (active) => {
    if (activeUrlPathPrefix !== null && isMicroFrontendName(activeUrlPathPrefix)) {
        const microFrontend = microFrontends[activeUrlPathPrefix];
        if (microFrontend) {
            microFrontend.active = active;
        }
    }
};
const preloadAllMicroFrontends = () => __awaiter(void 0, void 0, void 0, function* () {
    const promises = [];
    Object.values(microFrontends).forEach(element => {
        if (element) {
            promises.push(preloadMicroFrontend(element));
        }
    });
    yield Promise.all(promises);
});
const preloadMicroFrontend = (microFrontend) => __awaiter(void 0, void 0, void 0, function* () {
    yield microFrontend.loadMicroFrontend();
});
const switchToNewMicroFrontend = (newMicroFrontend) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        if (displayedMicroFrontend === null || !displayedMicroFrontend.isEqual(newMicroFrontend)) {
            removeErrorViewIfAvailable();
            yield unmountActiveMicroFrontend();
            yield bootstrapAndMountNewMicroFrontend(newMicroFrontend);
        }
    }
    catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
    }
});
const unmountActiveMicroFrontend = () => __awaiter(void 0, void 0, void 0, function* () {
    if (displayedMicroFrontend !== null) {
        yield displayedMicroFrontend.unmount();
        displayedMicroFrontend = null;
    }
});
const bootstrapAndMountNewMicroFrontend = (newMicroFrontend) => __awaiter(void 0, void 0, void 0, function* () {
    if (!newMicroFrontend.isAlreadyBootstrapped()) {
        yield newMicroFrontend.bootstrap();
    }
    yield newMicroFrontend.mount();
    displayedMicroFrontend = newMicroFrontend;
});
const callOnPushStateHandlers = () => {
    if (window.proalpha && window.proalpha.onPushStateHandlers) {
        window.proalpha.onPushStateHandlers.forEach(callback => callback());
    }
};
const initializeSession = (config) => {
    const isFederatedLogin = resumeSessionFromURL();
    const hideSignUp = !!config.hideSignUp;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-var-requires
    paAuthenticatorCorePatternsStyle.innerHTML =
        require('../../node_modules/@proalpha/session/dist/core-patterns.css').default.toString();
    const session = new Session({
        region: config.region,
        userPoolId: config.userPoolId,
        userPoolWebClientId: isFederatedLogin ? config.federatedUserPoolWebClientId : config.userPoolWebClientId,
    });
    if (window.proalpha === undefined) {
        window.proalpha = {};
    }
    window.proalpha.sessionInstance = session;
    session.addSignOnCallback(signedIn => {
        currentlySignedIn = signedIn;
        if (signedIn) {
            showShellContent();
        }
        else {
            void showAuthenticator(hideSignUp);
        }
    });
};
const showShellContent = () => {
    removeAuthenticatorFromDOM();
    showMicroFrontend();
};
const removeAuthenticatorFromDOM = () => {
    const paAuthenticator = document.querySelector('pa-authenticator');
    if (paAuthenticator) {
        document.body.removeChild(paAuthenticator);
    }
    document.head.removeChild(paAuthenticatorCorePatternsStyle);
};
const showAuthenticator = (hideSignUp) => __awaiter(void 0, void 0, void 0, function* () {
    removeErrorViewIfAvailable();
    yield unmountActiveMicroFrontend();
    addAuthenticatorToDOM(hideSignUp);
});
const addAuthenticatorToDOM = (hideSignUp) => {
    const paAuthenticator = document.createElement('pa-authenticator');
    paAuthenticator.setAttribute('hide-sign-up', String(hideSignUp));
    document.body.prepend(paAuthenticator);
    document.head.appendChild(paAuthenticatorCorePatternsStyle);
};
registerAllMicroFrontends();
loadConfig('/config/config.json', 'shell', shellConfigTestFunction).then(config => {
    if (config.microFrontendOverride) {
        initMicroFrontendOverrides();
    }
    initializeShell();
    initializeSession(config);
}, error => {
    // eslint-disable-next-line no-console
    console.error(error);
});
/**
 * Check URL parameters for session tokens, and inject them to localstorage to resume the session
 * This scenario is used when the user is redirected back to the app after a federated login
 */
const resumeSessionFromURL = () => {
    const urlParams = new URLSearchParams(window.location.hash.substring(1));
    const accessToken = sanitizeString(urlParams.get('access_token'));
    const idToken = sanitizeString(urlParams.get('id_token'));
    if (!accessToken || !idToken)
        return false;
    const accessTokenData = parseJwt(accessToken);
    const idTokenData = parseJwt(idToken);
    const username = idTokenData['cognito:username'];
    const clientId = accessTokenData['client_id'];
    if (!username || !clientId)
        return false;
    const accessTokenStorageKey = `CognitoIdentityServiceProvider.${clientId}.${username}.accessToken`;
    const idTokenStorageKey = `CognitoIdentityServiceProvider.${clientId}.${username}.idToken`;
    const lastAuthUserStorageKey = `CognitoIdentityServiceProvider.${clientId}.LastAuthUser`;
    localStorage.setItem(accessTokenStorageKey, accessToken);
    localStorage.setItem(idTokenStorageKey, idToken);
    localStorage.setItem(lastAuthUserStorageKey, username);
    return true;
};
const parseJwt = (token) => {
    if (token === '')
        return {};
    token = sanitizeString(token);
    const base64Url = token.split('.')[1];
    if (base64Url === '')
        return {};
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    try {
        const jsonPayload = decodeURIComponent(window
            .atob(base64)
            .split('')
            .map(c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
            .join(''));
        return JSON.parse(jsonPayload);
    }
    catch (error) {
        return {};
    }
};
const sanitizeString = (str) => {
    if (typeof str !== 'string')
        return '';
    return str.replace(/[\s`~!@#$%^&*()|+=?;:'",<>{}[\]\\/]/gi, '');
};
