export const checkIfMicroFrontendNeedsReloadNotification = (microFrontendTestValues) => {
    // Because the shell always tries to reload failed micro frontends
    // we don't have to display a reload message if we are not on the
    // micro frontend but just navigate to it.
    if (!microFrontendTestValues.active && microFrontendTestValues.problemLoading) {
        return false;
    }
    return (microFrontendTestValues.loadedHost !== null &&
        microFrontendTestValues.ownHost !== microFrontendTestValues.loadedHost);
};
